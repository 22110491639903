strong {
  font-weight: bold; }

.App {
  text-align: center; }

.App-nav {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 160px;
  margin-top: 32px; }

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 100px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: black;
  background-image: url("../../images/home/header-background.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 1024px) {
    .App-header {
      font-size: 64px; } }
  @media only screen and (max-width: 768px) {
    .App-header {
      font-size: 48px; } }
  @media only screen and (max-width: 600px) {
    .App-header {
      font-size: 24px; } }

.App-header-visual {
  display: block;
  width: 60%;
  margin: 0 auto; }
  @media only screen and (max-width: 600px) {
    .App-header-visual {
      width: 80%; } }

.App-header-date {
  position: absolute;
  bottom: 80px;
  font-size: 36px;
  line-height: 40px;
  padding: 0 10% 32px 10%;
  background-image: url("../../images/home/arrow.png");
  background-size: 32px auto;
  background-position: center bottom;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 600px) {
    .App-header-date {
      bottom: 16px;
      font-size: 20px;
      line-height: 24px; } }

.App-signup-title {
  position: relative;
  z-index: 0;
  margin-top: 64px;
  font-size: calc(10vmin);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase; }
  @media only screen and (max-width: 600px) {
    .App-signup-title {
      font-size: 42px; } }

.App-signup,
.App-box {
  position: relative;
  z-index: 1000;
  width: 30%;
  margin: 0 auto;
  margin-bottom: 64px;
  padding: 32px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 32px;
  border-radius: 8px; }
  @media only screen and (max-width: 768px) {
    .App-signup,
    .App-box {
      width: 50%; } }
  @media only screen and (max-width: 600px) {
    .App-signup,
    .App-box {
      width: 80%;
      margin-bottom: 32px; } }

.App-signup {
  padding-bottom: 0; }

.App-info-container {
  display: flex;
  flex-direction: row; }
  .App-info-container section {
    flex-grow: 1;
    width: 50%; }
  @media only screen and (max-width: 768px) {
    .App-info-container {
      flex-direction: column; }
      .App-info-container section {
        width: 100%; } }

.App-box {
  width: 50%; }
  .App-box h3 {
    margin-bottom: 24px;
    font-size: 32px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: bold; }
  .App-box p {
    margin-bottom: 12px;
    font-size: 16px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    line-height: 20px;
    text-align: center; }
  @media only screen and (max-width: 600px) {
    .App-box {
      width: 60%; } }

.App-fieldset {
  margin-bottom: 32px; }

.App-label {
  display: block;
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: left; }

.App-input[type="text"] {
  display: block;
  width: 100%;
  margin-top: 8px;
  padding: 16px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  appearance: none; }
  .App-input[type="text"]:focus {
    border-color: #FFE70F; }

.App-submit,
.App-button {
  display: block;
  width: 100%;
  padding: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  border: 4px solid #FFE70F;
  background-color: #FFE70F;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  appearance: none; }
  .App-submit:hover,
  .App-button:hover {
    color: #FFE70F;
    border-color: #000;
    background-color: #000; }
  .App-submit:active,
  .App-button:active {
    color: #000;
    border-color: #FFE70F;
    background-color: #FFF; }

.App-captcha {
  display: block;
  width: auto;
  height: 32px;
  margin-top: 8px;
  padding: 8px;
  border: 1px solid #000; }

.App-link {
  color: #61dafb; }

.App-bottomLogo {
  margin-bottom: 64px; }

.App-formError {
  margin-bottom: 12px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: red; }

.App-signup-successfully {
  font-family: Avenir, Helvetica, Arial, sans-serif; }
  .App-signup-successfully h3 {
    font-size: 32px; }
  .App-signup-successfully p {
    margin-top: 16px;
    line-height: 20px; }
  .App-signup-successfully .App-button {
    margin-top: 16px; }

.App-waiting-list-title {
  margin-bottom: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: red;
  line-height: 26px; }
